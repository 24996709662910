import React from "react";
import {t} from "i18next";


function ApplicationBtn(props) {
    const links = {
        android: process.env.REACT_APP_ANDROID_APP_URL,
        tv: process.env.REACT_APP_TV_APP_URL,
        ios: process.env.REACT_APP_IOS_APP_URL
    }

    return (
        <div className={`download-btns-parent w-100 position-relative text-center mt-4 ${props.className}`}>
            <div className="download-btns">

                <a href={links.android} title={t('android link')} className="btn btn-primary btn-download mx-2 mb-2" download>
                    <span>{t('android link')}</span>
                    <img 
                        src={"/images/icons/android.svg"}
                        width={35}
                        style={{marginRight: "10px"}}
                    />
                </a>

                <a href={links.tv} title={t('android link')} className="btn btn-primary btn-download mx-2 mb-2" download>
                    <span>{t('android tv link')}</span>
                    <img 
                        src={"/images/icons/androidtv.svg"}
                        width={35}
                        style={{marginRight: "10px"}}
                    />
                </a>

                {
                    links.ios && 
                    <a href={links.ios} title={t('android link')} className="btn btn-primary btn-download mx-2 mb-2" download>
                        <span>{t('ios link')}</span>
                        <img 
                            src={"/images/icons/iOS.svg"}
                            width={23}
                            style={{marginRight: "10px"}}
                        />
                    </a>
                }

            </div>
        </div>
    )
}
ApplicationBtn.defaultProps = {
    className: ''
}
export default ApplicationBtn;