import { detectLangCodeFromURL } from "../helper/helperFunctions";
import { store } from "../Redux/store";

function RoutesHelper(pageName) {
    let languages = store.getState().UserAuthReducer?.siteConfiguration?.languages;

    const detectedLang = detectLangCodeFromURL(languages);
    const { prefix } = detectedLang;

    switch (pageName) {
        case "HomeURL":
            return `${prefix}`;
        case "kids":
            return `${prefix}kids`;
        case "redirectToOAuth":
            return `/redirect/oAuth`; // /:service
        case "OAuth":
            return `/oAuth`;
        case "LoginURL":
            return `${prefix}auth`;
        case "RegisterURL":
            return `${prefix}register`;
        case "DeviceManagementURL":
            return `${prefix}remove-device`;
        case "ProfileURL":
            return `${prefix}profile`;
        case "DashboardURL":
            return `${prefix}profile/dashboard`;
        case "NotificationURL":
            return `${prefix}profile/notification`;
        case "DeviceMgmtURL":
            return `${prefix}profile/device-management`;
        case "SettingsURL":
            return `${prefix}profile/settings`;
        case "ActivityURL":
            return `${prefix}profile/activity`;
        case "AccountURL":
            return `${prefix}profile/account`;
        case "ParentalControlURL":
            return `${prefix}profile/parental-control`;
        case "SubscriptionURL":
            return `${prefix}subscription`;
        case "TvLogin":
            return `${prefix}activation`;
        case "TvLoginConfirmation":
            return `${prefix}activation/TvLoginConfirmation`;
        case "ChangePasswordURL":
            return `${prefix}profile/change-password`;
        case "PaymentURL":
            return `${prefix}pay`;
        case "CategoryURL":
            return `${prefix}category`;
        case "EditProfile":
            return `${prefix}profile/edit`;
        case "SearchURL":
            return `${prefix}search`;
        case "SingleMovie":
            return `${prefix}m`;
        case "Player":
            return `${prefix}v`;
        case "Live":
            return `${prefix}live`;
        case "LivePlayer":
            return `${prefix}LivePlayer`;
        case "CastAndCrew":
            return `${prefix}c`;
        case "General":
            return `${prefix}`;

        default:
            return "";
    }
}
export default RoutesHelper;
