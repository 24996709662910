import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Modal from 'react-bootstrap/Modal';
import { isAndroid, isIOS, isSmartTV } from "react-device-detect";

function ApplicationModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const links = {
        android: process.env.REACT_APP_ANDROID_APP_URL,
        tv: process.env.REACT_APP_TV_APP_URL,
        ios: process.env.REACT_APP_IOS_APP_URL
    }

    function runOncePerDay() {
        let lastRunDate = getCookie("lastRunDateApp");
        let currentDate = new Date().toDateString();

        if (!lastRunDate || lastRunDate !== currentDate) {
            setShow(true);
            setCookie("lastRunDateApp", currentDate, 1);
        } else {
            // Function executed today
        }
    }
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    useEffect(() => {
        runOncePerDay();
    }, []);

    if (isAndroid) {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName={'applicationModalContent'}
                className="applicationModalParent"
            >
                <Modal.Body>
                    <p>{t('application modal text')}</p>
                    <div className="w-100 d-flex flex-row modal-app-dl">
                        <a href={links.android} className="btn btn-primary btn-modal flex-grow-1 d-flex flex-row align-items-center justify-content-center" download>
                            <span className="pe-3">{t('android link')}</span>
                            {/* <svg width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.45893 1.21963C3.97328 0.731477 4.80721 0.731477 5.32156 1.21963L9.63015 5.30881C12.1306 4.06015 14.9783 3.35352 18 3.35352C21.0217 3.35352 23.8694 4.06015 26.3698 5.30881L30.6784 1.21963C31.1928 0.731477 32.0267 0.731477 32.5411 1.21963C33.0554 1.70779 33.0554 2.49924 32.5411 2.9874L28.6604 6.67042C33.1117 9.78049 36 14.7885 36 20.4369C36 20.5761 35.9982 20.715 35.9948 20.8535H0.00524915C0.00175634 20.715 0 20.5761 0 20.4369C0 14.7885 2.88828 9.78049 7.33958 6.67042L3.45893 2.9874C2.94458 2.49924 2.94458 1.70779 3.45893 1.21963ZM10.9756 15.0202C11.9455 15.0202 12.7317 14.274 12.7317 13.3535C12.7317 12.433 11.9455 11.6868 10.9756 11.6868C10.0057 11.6868 9.21951 12.433 9.21951 13.3535C9.21951 14.274 10.0057 15.0202 10.9756 15.0202ZM26.7805 13.3535C26.7805 14.274 25.9943 15.0202 25.0244 15.0202C24.0545 15.0202 23.2683 14.274 23.2683 13.3535C23.2683 12.433 24.0545 11.6868 25.0244 11.6868C25.9943 11.6868 26.7805 12.433 26.7805 13.3535Z" fill="white"/>
                            </svg> */}
                            <img
                                src={"/images/icons/android.svg"}
                                width={35}
                                style={{ marginRight: "10px" }}
                            />
                        </a>
                        <button type="button" className="btn btn-outline-primary btn-modal flex-shrink-0 ms-2" onClick={handleClose}>
                            {t('no')}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    if (isSmartTV) {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName={'applicationModalContent'}
                className="applicationModalParent"
            >
                <Modal.Body>
                    <p>{t('application modal text')}</p>
                    <div className="w-100 d-flex flex-row modal-app-dl">
                        <a href={links.tv} className="btn btn-primary btn-modal flex-grow-1 d-flex flex-row align-items-center justify-content-center" download>
                            <span className="pe-3">{t('android tv link')}</span>
                            {/* <svg width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.45893 1.21963C3.97328 0.731477 4.80721 0.731477 5.32156 1.21963L9.63015 5.30881C12.1306 4.06015 14.9783 3.35352 18 3.35352C21.0217 3.35352 23.8694 4.06015 26.3698 5.30881L30.6784 1.21963C31.1928 0.731477 32.0267 0.731477 32.5411 1.21963C33.0554 1.70779 33.0554 2.49924 32.5411 2.9874L28.6604 6.67042C33.1117 9.78049 36 14.7885 36 20.4369C36 20.5761 35.9982 20.715 35.9948 20.8535H0.00524915C0.00175634 20.715 0 20.5761 0 20.4369C0 14.7885 2.88828 9.78049 7.33958 6.67042L3.45893 2.9874C2.94458 2.49924 2.94458 1.70779 3.45893 1.21963ZM10.9756 15.0202C11.9455 15.0202 12.7317 14.274 12.7317 13.3535C12.7317 12.433 11.9455 11.6868 10.9756 11.6868C10.0057 11.6868 9.21951 12.433 9.21951 13.3535C9.21951 14.274 10.0057 15.0202 10.9756 15.0202ZM26.7805 13.3535C26.7805 14.274 25.9943 15.0202 25.0244 15.0202C24.0545 15.0202 23.2683 14.274 23.2683 13.3535C23.2683 12.433 24.0545 11.6868 25.0244 11.6868C25.9943 11.6868 26.7805 12.433 26.7805 13.3535Z" fill="white" />
                            </svg> */}
                            <img
                                src={"/images/icons/androidtv.svg"}
                                width={35}
                                style={{ marginRight: "10px" }}
                            />
                        </a>
                        <button type="button" className="btn btn-outline-primary btn-modal flex-shrink-0 ms-2" onClick={handleClose}>
                            {t('no')}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    if (isIOS && links.ios) {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName={'applicationModalContent'}
                className="applicationModalParent"
            >
                <Modal.Body>
                    <p>{t('application modal text')}</p>
                    <div className="w-100 d-flex flex-row modal-app-dl">
                        <a href={links.ios} className="btn btn-primary btn-modal flex-grow-1 d-flex flex-row align-items-center justify-content-center" download>
                            <span className="pe-3">{t('ios link')}</span>
                            {/* <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.6677 5.33063C14.7193 6.46796 13.1142 7.31644 11.8374 7.31644C11.6915 7.31644 11.5456 7.29839 11.4544 7.28034C11.4362 7.20813 11.3997 6.99149 11.3997 6.77486C11.3997 5.33063 12.1475 3.8864 12.95 2.98375C13.9715 1.79226 15.686 0.907674 17.1086 0.853516C17.1451 1.01599 17.1634 1.21457 17.1634 1.41315C17.1634 2.85738 16.5432 4.28356 15.6677 5.33063ZM16.6709 7.62334C17.4734 7.62334 20.3735 7.69555 22.2704 10.4035C22.1063 10.5299 19.2244 12.1366 19.2244 15.7291C19.2244 19.8812 22.8906 21.3616 23 21.3977C22.9818 21.4879 22.4163 23.4015 21.0666 25.3693C19.8628 27.0843 18.586 28.8174 16.6709 28.8174C14.7375 28.8174 14.245 27.6981 12.0381 27.6981C9.86757 27.6981 9.10151 28.8535 7.35052 28.8535C5.58128 28.8535 4.35924 27.2468 2.9548 25.2971C1.31324 22.9863 0 19.4119 0 16.0179C0 10.584 3.57494 7.69555 7.09516 7.69555C8.95559 7.69555 10.5059 8.9051 11.6915 8.9051C12.8041 8.9051 14.5369 7.62334 16.6709 7.62334Z" fill="white" />
                            </svg> */}
                            <img
                                src={"/images/icons/iOS.svg"}
                                width={23}
                                style={{ marginRight: "10px" }}
                            />
                        </a>
                        <button type="button" className="btn btn-outline-primary btn-modal flex-shrink-0 ms-2" onClick={handleClose}>
                            {t('no')}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
        </>
    )
}
ApplicationModal.defaultProps = {

}
export default ApplicationModal;